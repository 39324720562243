import React from 'react'

export default function Cucumber() {
  return (
    <svg
      aria-hidden='true'
      height='512pt'
      viewBox='0 -4 512.00022 512'
      width='512pt'
    >
      <path d='m439.933594 437.605469c-1.363282 0-2.746094-.28125-4.070313-.875-62.945312-28.117188-143.320312-94.429688-238.894531-197.09375-70.820312-76.070313-124.117188-144.9375-124.648438-145.621094-3.375-4.371094-2.5625-10.652344 1.808594-14.027344 4.367188-3.371093 10.648438-2.566406 14.023438 1.808594.523437.679687 53.347656 68.925781 123.566406 144.339844 64.410156 69.175781 157 158.699219 232.300781 192.339843 5.039063 2.25 7.300781 8.164063 5.050781 13.207032-1.660156 3.714844-5.3125 5.921875-9.136718 5.921875zm0 0' />
      <path d='m348.648438 434.347656c-1.683594 0-3.386719-.421875-4.949219-1.316406-71.828125-40.992188-133.566407-99.777344-172.707031-141.878906-42.355469-45.550782-67.449219-80.957032-68.496094-82.441406-3.183594-4.511719-2.105469-10.75 2.402344-13.933594 4.511718-3.179688 10.75-2.105469 13.933593 2.40625.253907.351562 25.527344 35.988281 67.03125 80.597656 38.148438 41 98.214844 98.199219 167.746094 137.882812 4.796875 2.734376 6.46875 8.84375 3.730469 13.640626-1.84375 3.230468-5.21875 5.042968-8.691406 5.042968zm0 0' />
      <path d='m86.003906 177.039062c-5.519531 0-10-4.476562-10-9.996093 0-5.523438 4.46875-10 9.992188-10h.007812c5.523438 0 10 4.476562 10 10 0 5.519531-4.476562 9.996093-10 9.996093zm0 0' />
      <path d='m486.347656 334.761719c-22.789062-37.054688-59.914062-73.777344-99.296875-98.234375-44.742187-27.804688-87.878906-61.652344-128.210937-100.605469-54.382813-52.53125-101.046875-85.585937-138.71875-98.265625-11.820313-3.980469-32.136719-8.847656-49.652344-3.257812-7.839844-11.320313-24.378906-31.828126-42.585938-34.167969-8.570312-1.097657-16.558593 1.730469-23.085937 8.191406-10.808594 10.675781-1.28125 29.863281 8.226563 45.382813 5.75 9.398437 12.058593 18.066406 15.558593 22.707031-15.625 33.695312-10.40625 78.675781 15.507813 131.378906 31.714844 64.519531 93.925781 138.179687 166.417968 197.042969 75.765626 61.53125 150.816407 97.59375 205.886719 98.953125 1.089844.03125 2.171875.042969 3.25.042969 36.527344 0 64.390625-15.5 80.71875-44.945313 19.347657-34.894531 14.5-77.847656-14.015625-124.222656zm-466.304687-313.164063c2.132812-1.640625 3.851562-1.71875 5.28125-1.527344 8.4375 1.070313 20.105469 13.515626 28.066406 24.765626-5 4.109374-9.488281 8.597656-13.398437 13.417968-2.351563-3.300781-5.058594-7.230468-7.710938-11.378906-10.117188-15.796875-11.925781-22.9375-12.238281-25.277344zm462.824219 427.699219c-12.738282 22.996094-33.996094 34.632813-63.203126 34.632813-.910156 0-1.839843-.007813-2.769531-.039063-50.75-1.25-121.378906-35.683594-193.789062-94.488281-70.351563-57.121094-130.570313-128.28125-161.078125-190.332032-18.316406-37.261718-34.675782-89.085937-9.019532-124.921874l18.367188-18.347657c9.261719-5.648437 53.933594-21.066406 173.578125 94.5 41.34375 39.941407 85.59375 74.667969 131.550781 103.214844 36.871094 22.894531 71.566406 57.191406 92.804688 91.726563 16.835937 27.363281 22.597656 49.910156 22.597656 67.957031 0 15.148437-4.0625 27.117187-9.039062 36.097656zm0 0' />
    </svg>
  )
}
