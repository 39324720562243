import React from 'react'
import styled from 'styled-components'
import {Link, useLocation} from 'react-router-dom'

import { abbr, currentMonth } from 'utils/months'

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 1em;
`
const Answer = styled.div`
  margin-bottom: 1.5rem;
  font-size: 4em;
  font-weight: 900;
  font-family: ${(props) => props.theme.trizzy.fonts.default};
  text-align: center;
  line-height: 1;
  color: ${(props) =>
    props.valid ? props.theme.trizzy.green : props.theme.trizzy.red};
  ${(props) => props.theme.mq.small} {
    font-size: 2.5em;
  }
`
const Months = styled.div`
  display: flex;
  ${(props) => props.theme.mq.small} {
    display: grid;
    grid: repeat(2, auto) / auto-flow;
  }
`
const Month = styled(Link)`
  flex: 1;
  display: block;
  padding: 1em 0;
  background-color: ${(props) =>
    props.active ? props.theme.trizzy.greenActive : props.valid ? props.theme.trizzy.green : props.theme.trizzy.red};
  border: 3px solid
    ${(props) => props.theme.colors.second};
  border-radius: 1em;
  font-size: 0.65em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  pointer-events: ${(props) => (props.iframe ? 'none' : 'inherit')};

  ${(props) => props.theme.mq.small} {
    font-size: 0.55em;
    border-width: 1px;
  }
`
export default function Year(props) {
  const location = useLocation();
  return (
    <Wrapper>
      <Answer
        valid={props.months.includes(currentMonth) ? 1 : 0}
        local={props.local ? 1 : 0}
      >
        {props.months.includes(currentMonth) || (props.months.includes(currentMonth) && props.local)
          ? 'Oui 😊' : 'Non 🙁'}
      </Answer>
      <Months>
        {abbr.map((month, index) => (
          <Month
            key={month}
            to={`/months/${index}${location.search}`}
            valid={props.months.includes(index) ? 1 : 0}
            active={index === currentMonth ? 1 : 0}
            iframe={props.iframe}
          >
            {month}
          </Month>
        ))}
      </Months>
    </Wrapper>
  )
}
