import React, {useContext} from 'react'
import { Link, useLocation } from 'react-router-dom'
import {ThemeContext} from "styled-components";

export default function MagicLink(props) {
  const { search } = useLocation()
  const { trizzyColor } = useContext(ThemeContext)
  return !props.to ? (
    <button className={props.className} onClick={props.onClick}>
      {props.children}
    </button>
  ) : props.to.includes('http') || props.to.includes('mailto') ? (
    <a
      className={props.className}
      href={props.to}
      onClick={props.onClick || null}
      target='_blank'
      rel='noreferrer noopener'
      style={{color: trizzyColor}}
    >
      {props.children}
    </a>
  ) : (
    <Link
      className={props.className}
      to={props.to + (!props.to.includes('?') && search)}
      onClick={props.onClick || null}
    >
      {props.children}
    </Link>
  )
}
