import React from 'react'
import styled from 'styled-components'
import CloseSvg from "./svg/close.svg";

const Wrapper = styled.div`
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: inherit;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 1em);
  pointer-events: ${(props) => (props.open ? 'inherit' : 'none')};
`
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 50px);
  background-color: ${(props) => (props.open ? props.theme.trizzyColor10 : props.backgroundColor)};
  transition: background-color ${(props) => (props.open ? '300ms' : 0)}
    ease-in-out;
`
const Content = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: ${(props) => props.width || '30em'};
  max-width: 90vw;
  max-height: 90vh;
  margin: 2rem;
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 1em;
  box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.2);

  opacity: ${(props) => (props.open ? 1 : 0)};
  transform: scale(${(props) => (props.open ? 1 : 0.7)}) translateY(${(props) => (props.open ? 0 : '10em')});
  transition: all ${(props) => (props.open ? '300ms' : 0)} ease-in-out;
`
const ButtonClose = styled.div`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-weight: Bolder;
  color: ${(props) => props.theme.trizzyColor};
  cursor: pointer;
  line-height: 0.5;
`
const Scroll = styled.div`
  overflow-y: auto;
  padding: 2rem;
`
export default function Modal(props) {
  return (
    <Wrapper open={props.open} className={props.className}>
      <Background open={props.open} onClick={() => props.setOpen(false)} />
      <Content
        open={props.open}
        width={props.width}
        textColor={props.textColor}
        backgroundColor={props.backgroundColor}
      >
        <ButtonClose onClick={() => props.setOpen(false)}>
          <CloseSvg width={"20px"}></CloseSvg>
        </ButtonClose>
        <Scroll>{props.children}</Scroll>
      </Content>
    </Wrapper>
  )
}
