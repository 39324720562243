import React, { useContext } from 'react'
import styled from 'styled-components'
import UXContext from 'utils/UXContext'
import LinkQuery from "../base/Link";
import ModalContext from "../../utils/ModalContext";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4vh 0;
  font-size: 6em;

  ${(props) => props.theme.mq.small} {
    margin: 0;
    padding: 2rem 3vw;
    font-size: 12vm;
  }
`
const Title = styled.h1`
  margin: 0;
  font-family: ${(props) => props.theme.trizzy.fonts.title};
  font-size: inherit;
  line-height: 1.1;
  ${(props) => props.theme.mq.small} {
    font-size: 9vm;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.background};
    filter: blur(20px);
  }
`

const NoLink = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.trizzyColor};
  text-decoration: none;
`
const Top = styled.span`
  position: relative;
  align-self: flex-start;
  display: block;

  span {
    position: relative;
  }
`
const ScrollToInformations = styled.a`
  position: relative;
  display: block;
  margin-right: 1.5rem;
  font-size: 0.9rem;
  font-style: italic;
  text-align: right;
  text-decoration: 1px underline;
  color: #808080;
  cursor: pointer;
  ${(props) => props.theme.mq.small} {
    font-size: 0.875rem;
  }
`
export default function Header(props) {
  const { displayTitle } = useContext(UXContext)
  const {setLearning} = useContext(ModalContext)

  return (
    <Wrapper>
      {displayTitle ? (
        <>
          <Title>
            {props.iframe ? (
              <NoLink>
                <Top text={'Est-ce bien la saison ?'}>
                  <span>Est-ce bien la saison ?</span>
                </Top>
              </NoLink>
            ) : (
              <LinkQuery
                path={'/'}
                label={`Est-ce bien la saison ?`}
                title={true}
                disableUnderline={true}
              >
              </LinkQuery>
            )}
          </Title>
          <ScrollToInformations
            onClick={() => setLearning(true)}
          >
            Pourquoi choisir des produits de saison ?
          </ScrollToInformations>
        </>
      ) : (
        ''
      )}
    </Wrapper>
  )
}
