import React from 'react'
import styled from 'styled-components'
import {Link, useLocation} from 'react-router-dom'
import useMounted from 'hooks/useMounted'

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  font-size: 1.2rem;
  text-align: center;

  opacity: ${(props) => (props.mounted ? 1 : 0)};
  //transition: opacity 1000ms 2000ms;
  color: ${(props) => props.theme.trizzyColor};
  ${(props) => props.theme.mq.small} {
    font-size: 1rem;
  }
`

const Top = styled.span`
  position: relative;
  align-self: flex-start;
  font-family: ${(props) => props.theme.trizzy.fonts.title};
  display: block;
  font-size: 2.3em;

  span {
    position: relative;
  }
`
export default function LinkQuery(props) {
  const mounted = useMounted()
  const location = useLocation();

  return (
    <StyledLink to={`${props.path}${location.search}`} mounted={mounted ? 1 : 0} style={{textDecoration: props.disableUnderline ? 'none' : '1px underline', fontSize: props.fontSize, transition: props.transition ? "opacity 1000ms 2000ms" : "none"}}>
      {props.title ? (
        <Top text={'Est-ce bien la saison ?'}>
          <span>{props.label}</span>
        </Top>
        ) : (<span>{props.label}</span> )}
    </StyledLink>
  )
}
