import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import useOnScreen from 'hooks/useOnScreen'
import ModalContext from 'utils/ModalContext'
import BarChart from 'components/charts/BarChart'

const Wrapper = styled.div`
  position: relative;
  max-width: 40em;
  margin: 0 auto 1em;

  ${(props) => props.theme.mq.small} {
    margin: 0 3vw 1em;
  }
  color: black;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.background};
    filter: blur(20px);
  }
`
const Content = styled.div`
  position: relative;
`
const Title = styled.h2`
  color: ${(props) => props.theme.trizzyColor};
  font-family: ${(props) => props.theme.trizzy.fonts.title};
  font-size: 1.8em;
  ${(props) => props.theme.mq.small} {
    font-size: 1.3em;
  }
`
const Statistic = styled.div`
  display: flex;
  margin-bottom: 1em;
  justify-content: flex-end;
  color: ${(props) => props.theme.trizzyColor};
`
const Number = styled.div`
  font-size: 6em;
  font-weight: 900;
  font-family: ${(props) => props.theme.trizzy.fonts.title};
  line-height: 1.1;
  opacity: ${(props) => (props.isOnScreen ? 1 : 0)};
  transition: opacity 1000ms;
  ${(props) => props.theme.mq.small} {
    font-size: 22vw;
  }
`
const BigText = styled.div`
  font-size: 3.5em;
  font-weight: 900;
  line-height: 0.8;
  text-transform: uppercase;
  ${(props) => props.theme.mq.small} {
    font-size: 12vw;
  }
`
const Line = styled.div`
  opacity: ${(props) => (props.isOnScreen ? 1 : 0)};
  transition: opacity 1000ms ${(props) => (props.bottom ? '800ms' : '400ms')};
`
const StrongWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
const Strong = styled.p`
  max-width: 23.75em;
  margin-right: 1em;
  font-size: 1.10em;
  font-weight: 700;
  font-style: italic;
  text-align: right;

  ${(props) => props.theme.mq.small} {
    margin-right: 0;
    text-align: left;
  }
`
const Text = styled.p``
const StyledLink = styled.span`
  cursor: pointer;
`
const A = styled.a`
  color: ${(props) => props.theme.trizzyColor};
  ${(props) => props.theme.mq.small} {
    font-size: 1rem;
  }
`
const StrongTrizzy = styled.strong`
  color: ${(props) => props.theme.trizzyColor};
`
export default function Learning() {
  const { setCO2E } = useContext(ModalContext)

  const ref = useRef()
  const isOnScreen = useOnScreen(ref, '-100px')

  return (
    <Wrapper id='informations'>
      <Content>
        <Title>
          Pourquoi choisir des produits de saison ?
        </Title>
        <Statistic ref={ref}>
          <Number isOnScreen={isOnScreen}>4</Number>
          <BigText>
            <Line isOnScreen={isOnScreen}>fois</Line>
            <Line isOnScreen={isOnScreen} bottom>
              plus
            </Line>
          </BigText>
        </Statistic>
        <StrongWrapper>
          <Strong>
            de{' '}
            <StrongTrizzy><StyledLink onClick={() => setCO2E(true)}>
              kgCO<sub>2</sub>e
            </StyledLink></StrongTrizzy>{' '}
            générés par <StrongTrizzy>une tomate produite hors saison</StrongTrizzy> par rapport à la même
            tomate produite à la bonne saison.
          </Strong>
        </StrongWrapper>
        <Text>
          Or, nous sommes environ 75% de Français à déclarer consommer des
          tomates en hiver. De même pour les fraises, 1 kg consommé en hiver
          génère 40% d'émissions de gaz à effet de serre de plus que la même
          quantité produite en saison. <StrongTrizzy>Bénéfiques pour le climat et la santé,
          les fruits et légumes de saison sont également meilleurs au goût</StrongTrizzy>.
        </Text>
        <BarChart />

        <Text>
          <StrongTrizzy>25% des émissions de gaz à effet de serre d'un Français sont liées à
          l'alimentation, devant les transports et le logement.</StrongTrizzy>
        </Text>
        <Text>
          <strong>Or, c’est un domaine dans lequel chacun dispose d’une capacité d’action.</strong> Il existe
          de nombreuses solutions simples et concrètes pour limiter ces
          émissions, responsables du changement climatique.
        </Text>
        <Text>
          Si vous souhaitez aller plus loin dans votre démarche, vous pouvez
          calculer votre empreinte sur le climat grace au simulateur <StrongTrizzy><A href={'https://nosgestesclimat.fr/'} target='_blank' rel='noopener noreferrer'>Nos Gestes Climat</A> de l'Ademe.</StrongTrizzy>
        </Text>
        <Title>D'où viennent ces données ? </Title>
        <Text>
          Ce simulateur réutilise les données du calendrier des fruits et légumes de saison de l'Ademe.
        </Text>
      </Content>
    </Wrapper>
  )
}
