import React from 'react'

export default function Peach() {
  return (
    <svg
      aria-hidden='true'
      height='511pt'
      viewBox='-18 0 511 511.99987'
      width='511pt'
    >
      <path d='m288.25 175.714844c-7.566406 0-15.128906.664062-22.585938 1.941406-.027343.011719-.058593.011719-.089843.019531-2.21875.386719-4.421875.84375-6.621094 1.332031-12.8125 0-30.195313 10.199219-40.40625 16.359376-78.96875-47.601563-172.25-1.679688-204.792969 78.726562-30.71875 75.910156-7.515625 173.4375 64.160156 217.757812 42.921876 26.535157 97.300782 26.914063 140.632813.816407 70.5625 42.523437 156.132813 10.5 194.925781-58.265625 37.125-65.800782 29.535156-154.203125-21.472656-210.695313-26.480469-29.328125-63.90625-47.992187-103.75-47.992187zm0 316.582031c-12.070312 0-24.101562-2.007813-35.554688-5.804687-5.398437-1.789063-10.671874-3.960938-15.757812-6.515626-5.121094-2.574218-11.199219-8.4375-16.953125-8.953124-9.265625-.824219-20.25 9.5625-28.746094 12.984374-11.945312 4.808594-24.710937 7.625-37.574219 8.1875-38.214843 1.660157-74.035156-17.03125-98.023437-46.191406-51.636719-62.777344-46.40625-165.292968 15.171875-219.738281 35.722656-31.585937 86.714844-39.859375 129.746094-18-23.953125 19.925781-41.453125 46.964844-51.28125 76.429687-5.394532 16.171876-8.5 33.078126-9.316406 50.105469-.28125 5.847657-1.570313 13.300781 3.875 17.292969 5.65625 4.144531 12.921874 1.109375 15.40625-4.914062 2.496093-6.054688.898437-16.382813 1.65625-22.871094.902343-7.765625 2.316406-15.464844 4.292968-23.027344 3.785156-14.488281 9.5625-28.480469 17.253906-41.328125 13.832032-23.101563 34.371094-43.328125 59.277344-54.15625 9.894532-4.726563 20.949219-7.703125 31.789063-9.097656h.019531c78.667969-11.238281 137.480469 62.539062 142.757812 134.761719 5.527344 75.574218-45.1875 160.835937-128.039062 160.835937zm0 0' />
      <path d='m220.222656 174.707031c-.527344 0-1.0625-.042969-1.601562-.128906-5.449219-.875-9.160156-6-8.292969-11.453125.035156-.234375 3.632813-23.820312-2.460937-49.636719-7.800782-33.046875-27.378907-54.398437-58.199219-63.460937-5.300781-1.5625-8.335938-7.121094-6.777344-12.425782 1.5625-5.300781 7.125-8.335937 12.421875-6.773437 37.90625 11.148437 62.867188 38.367187 72.183594 78.71875 6.824218 29.554687 2.769531 55.640625 2.59375 56.738281-.792969 4.917969-5.042969 8.421875-9.867188 8.421875zm0 0' />
      <path d='m250.585938 171.175781c-2.472657 0-4.867188-.917969-6.714844-2.589843-2.085938-1.886719-3.277344-4.5625-3.292969-7.371094-.007813-1.625.148437-40.183594 28.664063-79.5 16.625-22.929688 39.476562-41.394532 67.914062-54.878906 34.902344-16.554688 78.371094-25.582032 129.203125-26.83203175 3.101563-.07421875 6.109375 1.30859375 8.0625 3.74999975s2.652344 5.65625 1.890625 8.6875c-6.433594 25.636719-16.15625 64.378906-49.414062 95.957032-36.257813 34.429687-93.621094 54.949218-175.367188 62.730468-.316406.03125-.632812.046875-.945312.046875zm202.917968-150.640625c-42.253906 2.292969-78.460937 10.480469-107.773437 24.378906-25.371094 12.035157-45.652344 28.367188-60.289063 48.546876-16.019531 22.089843-21.710937 43.828124-23.734375 56.480468 70.65625-8.101562 120.265625-26.484375 151.410157-56.058594 23.054687-21.886718 33.085937-47.054687 40.386718-73.347656zm0 0' />
      <path d='m153.0625 390.539062c-5.527344 0-10.011719-4.480468-10.011719-10.003906 0-5.527344 4.476563-10.007812 10-10.007812h.011719c5.523438 0 10.003906 4.480468 10.003906 10.007812 0 5.523438-4.480468 10.003906-10.003906 10.003906zm0 0' />
    </svg>
  )
}
