import React, { useContext } from 'react'
import ModalContext from 'utils/ModalContext'
import Modal from 'components/base/Modal'
import Learning from "../misc/Learning";

export default function LearningModal() {
  const { learning, setLearning } = useContext(ModalContext)

  return (
    <Modal open={learning} setOpen={setLearning}>
      <Learning />
    </Modal>
  )
}
