import React, { Suspense } from 'react'
import styled from 'styled-components'
import useWindowSize from 'hooks/useWindowSize'
import Background from 'components/misc/Background'
import Header from 'components/misc/Header'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${(props) => props.theme.mq.medium} {
    flex-direction: column-reverse;
  }
  width: 100%;
`
const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const FullScreen = styled.div`
  position: relative;
  width: 41em;
  min-height: 100%;
  margin: 0 auto 5rem;
  padding: 0 0.5rem;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  ${(props) => props.theme.mq.small} {
    width: 90%;
  }
`
export default function Web(props) {
  const { height } = useWindowSize()

  return (
    <Wrapper>
      <Suspense fallback={''}>
        <Background />
      </Suspense>
      <Content>
        <FullScreen windowHeight={height}>
          <Header />
          {props.children}
        </FullScreen>
      </Content>
    </Wrapper>
  )
}
