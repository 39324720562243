import React from 'react'

export default function Acorn() {
  return (
    <svg
      aria-hidden='true'
      height='511pt'
      viewBox='-77 0 511 511.99858'
      width='511pt'
    >
      <path d='m256.5 88.066406h-46.75c-.960938-33.546875 27.179688-45.160156 28.429688-45.648437 2.820312-1.089844 5-3.390625 5.929687-6.269531.929687-2.878907.5-6.03125-1.160156-8.5625-.308594-.46875-3.191407-4.75-9.160157-10.097657-7.761718-6.941406-17.089843-12.589843-27.738281-16.789062-2.339843-.921875-4.929687-.929688-7.273437-.03125-35.796875 13.808593-48.1875 40.359375-52.277344 60.191406-2.152344 10.4375-2.242188 20.066406-1.769531 27.207031h-44.230469c-55.140625 0-100 44.859375-100 100v31.5c0 16.542969 13.460938 30 30 30h19.167969v120.742188c0 34.640625 13.722656 70.078125 37.652343 97.25 25.238282 28.660156 58.039063 44.441406 92.347657 44.441406 34.320312 0 67.121093-15.78125 92.359375-44.441406 23.921875-27.171875 37.640625-62.609375 37.640625-97.25v-120.738282h16.832031c16.539062 0 30-13.460937 30-30v-31.5c0-55.144531-44.859375-100.003906-100-100.003906zm-54.160156-67.238281c7 3.160156 12.320312 6.769531 16.140625 9.898437-3.480469 2.460938-7.339844 5.640626-11.089844 9.652344-8.742187 9.367188-18.210937 24.847656-17.671875 47.6875h-24.988281c-1.359375-15.667968.207031-50.71875 37.609375-67.238281zm87.328125 349.480469c0 29.839844-11.898438 60.46875-32.648438 84.03125-10.699219 12.140625-22.910156 21.558594-36.011719 27.9375-13.097656 6.382812-27.089843 9.722656-41.339843 9.722656-28.488281 0-55.957031-13.382812-77.339844-37.660156-20.75-23.5625-32.660156-54.191406-32.660156-84.03125v-120.738282h220zm46.832031-150.738282c0 5.507813-4.492188 10-10 10h-296c-5.511719 0-10-4.492187-10-10v-31.5c0-44.113281 35.890625-80 80-80h156c44.109375 0 80 35.886719 80 80zm0 0' />
      <path d='m196.96875 461.75c-3.65625 0-7.175781-2.011719-8.9375-5.5-2.484375-4.929688-.503906-10.945312 4.425781-13.429688 28.976563-14.617187 49.214844-48.027343 49.214844-81.253906 0-5.523437 4.476563-10 10-10s10 4.476563 10 10c0 40.476563-24.757813 81.230469-60.207031 99.109375-1.445313.730469-2.980469 1.074219-4.496094 1.074219zm0 0' />
      <path d='m250.964844 332.273438c-5.523438 0-10.007813-4.476563-10.007813-10 0-5.523438 4.472657-10 9.996094-10h.011719c5.519531 0 10 4.476562 10 10 0 5.523437-4.480469 10-10 10zm0 0' />
      <path d='m180.164062 157.265625c-5.523437 0-10.003906-4.476563-10.003906-10 0-5.519531 4.46875-10 9.992188-10h.011718c5.523438 0 10 4.480469 10 10 0 5.523437-4.476562 10-10 10zm0 0' />
      <path d='m228.164062 157.265625c-5.523437 0-10.003906-4.476563-10.003906-10 0-5.519531 4.46875-10 9.992188-10h.011718c5.523438 0 10 4.480469 10 10 0 5.523437-4.476562 10-10 10zm0 0' />
      <path d='m132.164062 157.265625c-5.523437 0-10.003906-4.476563-10.003906-10 0-5.519531 4.46875-10 9.992188-10h.011718c5.523438 0 10 4.480469 10 10 0 5.523437-4.476562 10-10 10zm0 0' />
      <path d='m84.164062 169.238281c-5.523437 0-10.003906-4.476562-10.003906-10 0-5.523437 4.46875-10 9.992188-10h.011718c5.523438 0 10 4.476563 10 10 0 5.523438-4.476562 10-10 10zm0 0' />
      <path d='m276.164062 169.238281c-5.523437 0-10.003906-4.476562-10.003906-10 0-5.523437 4.46875-10 9.992188-10h.011718c5.523438 0 10 4.476563 10 10 0 5.523438-4.476562 10-10 10zm0 0' />
      <path d='m204.164062 205.15625c-5.523437 0-10.003906-4.480469-10.003906-10 0-5.523438 4.46875-10 9.992188-10h.011718c5.523438 0 10 4.476562 10 10 0 5.519531-4.476562 10-10 10zm0 0' />
      <path d='m156.164062 205.15625c-5.523437 0-10.003906-4.480469-10.003906-10 0-5.523438 4.46875-10 9.992188-10h.011718c5.523438 0 10 4.476562 10 10 0 5.519531-4.476562 10-10 10zm0 0' />
    </svg>
  )
}
