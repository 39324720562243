import React, { useState, useContext } from 'react'
import { ThemeProvider } from 'styled-components'
import { useQueryParam, StringParam, withDefault } from 'use-query-params'

import StyleContext from 'utils/StyleContext'
import UXContext from 'utils/UXContext'
import {hexToRgbAOpa20, themes, trizzyColor} from 'utils/styles'

export default function CO2NumberProvider(props) {
  const [theme, setTheme] = useQueryParam(
    'theme',
    withDefault(StringParam, 'default')
  )

  const [color] = useQueryParam(
    'color',
    withDefault(StringParam, trizzyColor.substring(1))
  )
  const { embedOpen } = useContext(UXContext)
  const [accessibility, setAccessibility] = useState(false)

  return (
    <StyleContext.Provider
      value={{
        themes,
        theme,
        setTheme,
        accessibility,
        setAccessibility,
      }}
    >
      <ThemeProvider
        theme={{
          ...themes[accessibility && !embedOpen ? 'classic' : theme],
          trizzyColor: '#' + color,
          trizzyColor10: hexToRgbAOpa20('#'+ color, 10),
          trizzyColor55: hexToRgbAOpa20('#'+ color, 55),
        }}
      >
        {props.children}
      </ThemeProvider>
    </StyleContext.Provider>
  )
}
