import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { months } from 'utils/months'
import ProductContext from 'utils/ProductContext'

import Product from './months/Product'
import LinkQuery from "../components/base/Link";
import Suggestions from "../components/misc/Suggestions";

const Wrapper = styled.div`
  ${(props) => props.theme.mq.xlarge} {
    width: 50%;
  }
  ${(props) => props.theme.mq.large} {
    width: 60%;
  }
  ${(props) => props.theme.mq.medium} {
    width: 70%;
  }
  ${(props) => props.theme.mq.small} {
    width: 90%;
  }
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`
const Title = styled.h2`
  margin: 0;
  font-size: 1.7em;
  text-align: center;
  color: black;
  ${(props) => props.theme.mq.small} {
    font-size: 1.1em;
  }
`
const Products = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1em;

  ${(props) => props.theme.mq.small} {
    margin: 0 -1.5vw;
  }
`
const Strong = styled.strong`
  color: ${(props) => props.theme.trizzyColor};
`
const Text = styled.p``
export default function Listing(props) {
  const { month } = useParams()

  const { products } = useContext(ProductContext)

  const [filteredProducts, setFilteredProducts] = useState([])

  useEffect(() => {
    setFilteredProducts(
      products
        .filter((product) => product.months.includes(Number(month)))
        .sort((a, b) => (a.local > b.local ? -1 : 1))
    )
  }, [products, month])

  return (
    <Wrapper>
      <Header>
        <LinkQuery path={`/months/${month > 0 ? Number(month) - 1 : 11}`} label={"❮"} disableUnderline={true} fontSize={"2em"}>
        </LinkQuery>
        <Title>
          Tous les fruits et légumes{' '}
          {[3, 7, 9].includes(Number(month)) ? `d'` : 'de '}
          <Strong>{months[month]}</Strong>
        </Title>
        <LinkQuery path={`/months/${month < 11 ? Number(month) + 1 : 0}`} label={"❯"}  disableUnderline={true} fontSize={"2em"}>
        </LinkQuery>
      </Header>
      <Products>
        {filteredProducts.map((product) => (
          <Product key={product.label.fr} product={product} />
        ))}
      </Products>
      <Text>Découvrir d'autres fruits & légumes : </Text>
      <Suggestions isPageMonths={true} length={5} iframe={props.iframe} />
    </Wrapper>
  )
}
