import React from 'react'

export default function Peanut() {
  return (
    <svg
      aria-hidden='true'
      height='512pt'
      viewBox='-8 0 512 512.00002'
      width='512pt'
    >
      <path d='m101.382812 512c-29.144531 0-54.570312-9.695312-73.125-28.226562-23.351562-23.332032-32.691406-57.546876-26.292968-96.34375 6.195312-37.578126 26.550781-75.199219 57.3125-105.925782 20.359375-20.34375 44.277344-36.480468 69.167968-46.664062 40.632813-16.632813 71.628907-50.394532 85.042969-92.632813 9.519531-29.96875 27.421875-58.667969 51.769531-82.992187 30.761719-30.730469 68.421876-51.0625 106.039063-57.25 38.839844-6.390625 73.089844 2.9375 96.445313 26.265625 23.351562 23.328125 32.6875 57.542969 26.292968 96.339843-6.199218 37.578126-26.550781 75.199219-57.3125 105.929688-20.363281 20.339844-44.28125 36.476562-69.171875 46.660156-40.628906 16.632813-71.628906 50.394532-85.042969 92.632813-9.519531 29.972656-27.417968 58.671875-51.765624 82.992187-30.765626 30.730469-68.421876 51.0625-106.039063 57.253906-7.984375 1.3125-15.777344 1.960938-23.320313 1.960938zm292.949219-492.066406c-37.113281 0-80.101562 18.640625-114.917969 53.421875-22.089843 22.070312-38.289062 47.96875-46.84375 74.898437-15.214843 47.902344-50.398437 86.207032-96.535156 105.089844-22.453125 9.1875-44.101562 23.816406-62.601562 42.296875-56.570313 56.511719-70.484375 134.5625-31.019532 173.988281 39.46875 39.429688 117.601563 25.527344 174.171876-30.984375 22.09375-22.070312 38.289062-47.96875 46.84375-74.898437 15.210937-47.902344 50.398437-86.207032 96.535156-105.089844 22.453125-9.1875 44.097656-23.816406 62.601562-42.296875 27.828125-27.800781 46.195313-61.550781 51.714844-95.039063 5.320312-32.265624-2.027344-60.304687-20.695312-78.949218-15.175782-15.160156-36.070313-22.4375-59.253907-22.4375zm0 0' />
      <path d='m321.5 94.410156c-2.5625 0-5.125-.976562-7.078125-2.929687-3.910156-3.902344-3.910156-10.234375 0-14.140625 18.855469-18.832032 41.992187-31.304688 65.148437-35.113282 24.382813-4.007812 45.964844 1.929688 60.777344 16.726563 3.910156 3.902344 3.910156 10.234375 0 14.140625-3.910156 3.902344-10.246094 3.902344-14.15625 0-10.125-10.117188-25.53125-14.070312-43.367187-11.132812-19.0625 3.132812-38.328125 13.621093-54.246094 29.519531-1.957031 1.953125-4.519531 2.929687-7.078125 2.929687zm0 0' />
      <path d='m252.800781 321.742188c-1.609375 0-3.242187-.386719-4.757812-1.207032-4.859375-2.632812-6.667969-8.699218-4.035157-13.554687 13.960938-25.753907 28.460938-45.191407 57.5625-62.5 4.75-2.828125 10.894532-1.269531 13.722657 3.472656 2.828125 4.746094 1.269531 10.882813-3.480469 13.707031-25.269531 15.03125-37.542969 31.492188-50.203125 54.84375-1.808594 3.34375-5.253906 5.238282-8.808594 5.238282zm0 0' />
      <path d='m63.242188 454.738281c-2.558594 0-5.121094-.976562-7.078126-2.925781-9.507812-9.5-13.203124-24.875-10.683593-44.46875 2.511719-19.535156 11.25-42.640625 23.972656-63.390625 13.6875-22.3125 30.898437-40.265625 48.472656-50.546875 4.765625-2.796875 10.898438-1.191406 13.695313 3.570312 2.792968 4.765626 1.191406 10.890626-3.578125 13.683594-32.308594 18.90625-58.507813 66.574219-62.707031 99.230469-1.675782 13.03125.09375 22.894531 4.984374 27.777344 3.910157 3.90625 3.910157 10.238281 0 14.140625-1.953124 1.953125-4.515624 2.929687-7.078124 2.929687zm0 0' />
      <path d='m156.933594 290.996094c-5.527344 0-10.011719-4.476563-10.011719-10 0-5.519532 4.472656-10 10.003906-10h.007813c5.527344 0 10.007812 4.480468 10.007812 10 0 5.523437-4.480468 10-10.007812 10zm0 0' />
    </svg>
  )
}
