import React from 'react'

export default function Kiwi() {
  return (
    <svg
      aria-hidden='true'
      height='512pt'
      viewBox='-88 0 512 512'
      width='512pt'
    >
      <path d='m286.648438 99.839844c-18.007813-17.960938-39.898438-31.660156-63.726563-39.960938 2.957031-31.742187-23.210937-59.878906-54.921875-59.878906-31.691406 0-57.898438 28.128906-54.921875 59.871094-67.007813 23.289062-113.078125 87.179687-113.078125 158.390625v126.109375c0 91.28125 76.839844 167.628906 168 167.628906s168-76.347656 168-167.628906v-126.109375c0-44.601563-17.519531-86.660157-49.351562-118.421875-18.007813-17.960938 31.832031 31.761718 0 0zm-118.648438-79.839844c19.140625 0 34.761719 15.339844 35.140625 34.328125-23.199219-4.871094-47.082031-4.871094-70.28125 0 .378906-18.988281 16-34.328125 35.140625-34.328125zm148 324.371094c0 80.289062-67.730469 147.746094-148 147.628906-80.265625-.117188-148-67.164062-148-147.628906v-126.109375c0-96.390625 96.019531-168.65625 188.820312-141.890625h.007813c62.453125 18.023437 107.171875 76.84375 107.171875 141.890625zm0 0' />
      <path d='m170.257812 466.960938c-70.410156 0-127.695312-57.160157-127.695312-127.421876 0-5.519531 4.480469-10 10-10 5.523438 0 10 4.480469 10 10 0 59.234376 48.3125 107.421876 107.695312 107.421876 5.519532 0 10 4.480468 10 10 0 5.523437-4.476562 10-10 10zm0 0' />
      <path d='m52.582031 304.601562c-5.523437 0-10.007812-4.476562-10.007812-10 0-5.523437 4.476562-10 9.996093-10h.011719c5.523438 0 10 4.476563 10 10 0 5.523438-4.476562 10-10 10zm0 0' />
      <path d='m168.019531 116.855469c-5.523437 0-10.003906-4.476563-10.003906-10 0-5.519531 4.472656-10 9.996094-10h.007812c5.523438 0 10 4.480469 10 10 0 5.523437-4.476562 10-10 10zm0 0' />
      <path d='m205.261719 122.488281c-5.519531 0-10.003907-4.480469-10.003907-10 0-5.523437 4.472657-10 9.996094-10h.007813c5.523437 0 10 4.476563 10 10 0 5.519531-4.476563 10-10 10zm0 0' />
      <path d='m239.117188 140.503906c-5.519532 0-10.003907-4.480468-10.003907-10 0-5.523437 4.472657-10 9.996094-10h.007813c5.523437 0 10 4.476563 10 10 0 5.519532-4.472657 10-10 10zm0 0' />
      <path d='m265.078125 167.523438c-5.523437 0-10.007813-4.476563-10.007813-10 0-5.519532 4.476563-10 9.996094-10h.011719c5.523437 0 10 4.480468 10 10 0 5.523437-4.476563 10-10 10zm0 0' />
      <path d='m279.746094 197.925781c-5.519532 0-10.003906-4.476562-10.003906-10 0-5.519531 4.472656-10 9.996093-10h.007813c5.523437 0 10 4.480469 10 10 0 5.523438-4.476563 10-10 10zm0 0' />
      <path d='m193.976562 156.265625c-5.523437 0-10.003906-4.476563-10.003906-10s4.472656-10 9.996094-10h.007812c5.523438 0 10 4.476563 10 10s-4.476562 10-10 10zm0 0' />
      <path d='m228.960938 181.035156c-5.519532 0-10.003907-4.476562-10.003907-10 0-5.519531 4.472657-10 9.996094-10h.007813c5.523437 0 10 4.480469 10 10 0 5.523438-4.476563 10-10 10zm0 0' />
      <path d='m192.847656 193.421875c-5.523437 0-10.003906-4.476563-10.003906-10 0-5.519531 4.472656-10 9.996094-10h.007812c5.523438 0 10 4.480469 10 10 0 5.523437-4.476562 10-10 10zm0 0' />
    </svg>
  )
}
