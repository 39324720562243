import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Fuse from '../../node_modules/fuse.js/dist/fuse.basic.esm.min.js'
import { currentMonth } from 'utils/months'
import SearchContext from 'utils/SearchContext'
import ProductContext from 'utils/ProductContext'
import Suggestions from 'components/misc/Suggestions'
import Result from './results/Result'
import NotFound from './results/NotFound'
import deburr from 'lodash.deburr';
import LinkQuery from "../components/base/Link";

const Wrapper = styled.div`
  width: inherit;
  min-height: 22em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`
export default function Results(props) {
  const { search } = useContext(SearchContext)
  const { products } = useContext(ProductContext)
  const [filteredProducts, setFilteredProducts] = useState([])

  const [fuse, setFuse] = useState(null)
  useEffect(() => {
    setFuse(
      new Fuse(products, {
        keys: [{ name: 'label', getFn: (product) => deburr(product.label.fr)}],
        threshold: 0.3,
        isCaseSensitive: false,
        minMatchCharLength: 3,
        matchAllOnEmptyQuery: true,
      })
    )
  }, [products])

  useEffect(() => {
    if (fuse) {
      const valueDeburr = deburr(search)
      setFilteredProducts(fuse.search(valueDeburr));
    }
  }, [search, products, fuse])
  return (
    <Wrapper>
      {filteredProducts.length ? (
        filteredProducts.map(
          (product, index) =>
            (!props.iframe || index === 0) && (
              <Result
                key={product.item.label.fr}
                index={index}
                product={product.item}
                iframe={props.iframe}
              />
            )
        )
      ) : search.length > 2 ? (
        <NotFound iframe={props.iframe} />
      ) : (
        <Suggestions length={5} iframe={props.iframe} />
      )}
      {!props.iframe && (
        <LinkQuery path={`/months/${currentMonth}`} label={"👉🏻 Voir tous les produits du mois"} transition={true}>
        </LinkQuery>
      )}
    </Wrapper>
  )
}
