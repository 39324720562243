import React, { useContext } from 'react'
import styled from 'styled-components'
import ModalContext from 'utils/ModalContext'
import Modal from 'components/base/Modal'

const Title = styled.h2`
  color: ${(props) => props.theme.trizzyColor};
  font-family: ${(props) => props.theme.trizzy.fonts.title};
  font-size: 1.8em;
`
const Text = styled.p``
const Strong = styled.strong`
  color: ${(props) => props.theme.trizzyColor};
`
export default function TransportationModal() {
  const { local, setLocal } = useContext(ModalContext)

  return (
    <Modal open={local} setOpen={setLocal}>
      <Title>Pourquoi consommer local ?</Title>
      <Text>
        Se fournir chez des agriculteurs du territoire permet de <Strong>soutenir une activité économique locale</Strong>, de <Strong>réduire les transports et les pertes</Strong>, ainsi que de <Strong>mieux connaître la qualité et l’origine des produits</Strong>.
      </Text>
      <Text>
        Quand on consomme des aliments qui viennent de loin, il ne faut pas oublier que <Strong>ces produits ont du faire un long voyage pour arriver jusque notre assiette</Strong>. L’avion est le mode de transport le plus consommateur d’énergie et émetteur de gaz à effet de serre, suivi par le transport terrestre et le bateau.
      </Text>
      {/*<Text>*/}
      {/*  Si vous souhaitez aller plus loin dans votre démarche, vous pouvez <Strong>comparer les différents moyens de transport</Strong> grâce au <Strong>simulateur <MagicLink to={'https://monimpacttransport.fr/'}>Mon Impact Transport</MagicLink> de l'Ademe</Strong>.*/}
      {/*</Text>*/}
    </Modal>
  )
}
