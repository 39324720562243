import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

export const themes = {
  default: {
    name: 'Défaut',
    colors: {
      main: '#EF0D50',
      second: '#ffffff',
      ter: '#32337B',
      quad: '#ffffff',
      background: '#ffffff',
      text: '#32337B',
    },
    trizzy: {
      green: '#6BCD6A',
      greenActive: '#4D9F4C',
      red: '#FB403C',
      fonts: {
        default: '"Monserrat", sans-serif',
        title: '"KGAlwaysAGoodTime", serif',
      }
    },
    fonts: {
      body: '"Work Sans", sans-serif',
      title: '"Work Sans", sans-serif',
    },
    mq: {
      small: `@media screen and (max-width: ${650}px)`,
      medium: `@media screen and (max-width: ${1260}px)`,
      mediumLandscape: `@media screen and (orientation: landscape) and (max-width: ${1260}px)`,
      mediumPortrait: `@media screen and (orientation: portrait) and (max-width: ${1260}px)`,
      large: `@media screen and (min-width: ${1260}px)`,
      xlarge: `@media screen and (min-width: ${1800}px)`,
    },
  },
  classic: {
    name: 'Classique',
    colors: {
      main: '#EF0D50',
      second: '#ffffff',
      ter: '#32337B',
      quad: '#ffffff',
      background: '#ffffff',
      text: '#32337B',
    },
    trizzy: {
      green: '#6BCD6A',
      greenActive: '#4D9F4C',
      red: '#FB403C',
      fonts: {
        default: '"Monserrat", sans-serif',
        title: '"KGAlwaysAGoodTime", serif',
      }
    },
    fonts: {
      body: '"Work Sans", sans-serif',
      title: '"Work Sans", sans-serif',
    },
    mq: {
      small: `@media screen and (max-width: ${650}px)`,
      medium: `@media screen and (max-width: ${1260}px)`,
      mediumLandscape: `@media screen and (orientation: landscape) and (max-width: ${1260}px)`,
      mediumPortrait: `@media screen and (orientation: portrait) and (max-width: ${1260}px)`,
      large: `@media screen and (min-width: ${1800}px)`,
      xlarge: `@media screen and (min-width: ${2000}px)`,
    },
  },
  // night: {
  //   name: 'Nuit',
  //   colors: {
  //     main: '#92F2D2',
  //     second: '#1e1e1e',
  //     ter: '#ffffff',
  //     quad: '#1e1e1e',
  //     background: '#1e1e1e',
  //     text: '#ffffff',
  //   },
  //   fonts: {
  //     body: '"Work Sans", sans-serif',
  //     title: '"Work Sans", sans-serif',
  //   },
  //   mq: {
  //     small: `@media screen and (max-width: ${650}px)`,
  //     medium: `@media screen and (max-width: ${1260}px)`,
  //     mediumLandscape: `@media screen and (orientation: landscape) and (max-width: ${1260}px)`,
  //     mediumPortrait: `@media screen and (orientation: portrait) and (max-width: ${1260}px)`,
  //     large: `@media screen and (min-width: ${1800}px)`,
  //     xlarge: `@media screen and (min-width: ${2000}px)`,
  //   },
  // },
  // simple: {
  //   name: 'Simple',
  //   colors: {
  //     main: '#000091',
  //     second: '#ffffff',
  //     ter: '#1e1e1e',
  //     quad: '#ffffff',
  //     background: '#ffffff',
  //     text: '#1e1e1e',
  //   },
  //   fonts: {
  //     body: '"Work Sans", sans-serif',
  //     title: '"Work Sans", sans-serif',
  //   },
  //   mq: {
  //     small: `@media screen and (max-width: ${650}px)`,
  //     medium: `@media screen and (max-width: ${1260}px)`,
  //     mediumLandscape: `@media screen and (orientation: landscape) and (max-width: ${1260}px)`,
  //     mediumPortrait: `@media screen and (orientation: portrait) and (max-width: ${1260}px)`,
  //     large: `@media screen and (min-width: ${1800}px)`,
  //     xlarge: `@media screen and (min-width: ${2000}px)`,
  //   },
  // },
}

export const trizzyColor = '#161b87'

export function hexToRgbAOpa20(hex, pourtcentage) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    // eslint-disable-next-line no-bitwise
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      (pourtcentage / 100).toString() +
      ')'
    );
  }
  throw new Error('Bad Hex');
}

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  *{
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  ::-webkit-scrollbar {
    display: none;
  }

  html {
    box-sizing: border-box;
    font-family: ${(props) => props.theme.trizzy.fonts.default};
    scroll-behavior: smooth;
    line-height: 1.4;
    width: 100%;

    ${(props) => props.theme.mq.small} {
      font-size: 0.875em;
    }
  }

  @font-face {
    font-family: KGAlwaysAGoodTime;
    src: url('https://cdn.trizzy.io/trizzyio/assets/font/KGAlwaysAGoodTime.ttf')
    format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  body {
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    font-family: ${(props) => props.theme.trizzy.fonts.default};;
    height: inherit;
    width: inherit;
  }

  #root{
    height: inherit;
    width: inherit;
  }

  *, *:before, *:after {
    margin-top: 0;
    box-sizing: inherit;
  }

  #root {
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-family: ${(props) => props.theme.trizzy.fonts.default};
    font-style: italic;
  }

  a {
    color: ${(props) => props.theme.colors.main};
  }

  option {
    color: #010101;
  }
`
